<template>
    <div class="page-body wrapper-fixed">

        <div class="login-control d-flex">
            <language-switcher class="ml-auto"></language-switcher>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background"/>
        </div>
        <div class="col-md-6 login-content">
            <div class="text-center">
                <h4 class="page-title"> {{ $t('Get Discovered by Top Employer and Grab Your Dream Job.')}}</h4>
                <p class="page-description"> {{ $t('Find your dream jobs through the best applicant tracking system')}}</p>
            </div>
            <div class="login-box">
                <h2 class="login-box__title text-capitalize"> {{ $t('please login')}} </h2>
                <div class="signup-message text-center"> {{ $t("Don't have an account?")}}
                    <router-link :to="{name : 'registration', query: $route.query}" class="color-primary">{{ $t("Sign Up")}}</router-link>
                </div>
                <div class="social-signin">
                    <button class="social-signin__button" @click="AuthProvider('google')">
                        <div class="social-signin__button__logo">
                            <img src="/img/google-colorfull.svg" alt="" class="img-fluid"/>
                        </div>
                        <p class="social-signin__button__text">{{ $t("Signin with Google")}}</p>
                    </button>
                    <button class="social-signin__button" @click="AuthProvider('linkedin')">
                        <div class="social-signin__button__logo">
                            <i class="eicon e-linkedin-cf"></i>
                        </div>
                        <p class="social-signin__button__text">{{ $t("Signin with LinkedIn")}}</p>
                    </button>
                </div>
                <alert></alert>
                <form class="login-form">
                    <div class="form-group">
                        <label class="text-capitalize">{{ $t("email address")}}</label>
                        <input class="form-control" v-model="form.email" :class="{'has-error' : errors.email}" maxlength="100" type="email" placeholder="youremail@gmail.com"/>
                        <error-message :message="$t(errors.email)"/>
                    </div>
                    <div class="form-group">
                        <label class="text-capitalize">{{ $t("Password")}}</label>
                        <input class="form-control" v-model="form.password" :class="{'has-error' : errors.password}" type="password" :placeholder="$t('Enter your password')"/>
                        <error-message :message="$t(errors.password)"/>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                        <submit-button :click="signIn" :block="true" :loading="isLoading" style="width: unset!important;">{{ $t("Sign In")}}</submit-button>
                        <router-link :to="{name:'password.forget'}" class="forget-button">{{ $t("Forgot Password?")}}</router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import {mapGetters, mapActions} from 'vuex';
    const LanguageSwitcher = () => import("../components/dropdown/LanguageSwitcher.vue");
    import {SWITCH_TO_ACCOUNT, USER_LOGGED_IN} from '@/constants/action-type';
    import client from "../app/api/Client";
    import SiteLogoSVG from "../components/SiteLogoSVG";
    import {USER_TYPE} from "@/constants/enums";
    const ErrorMessage = () => import("../components/common/ErrorMessage.vue");
    import VueSocialAuth from 'vue-social-auth';
    import authConfig from '../config/auth';
    Vue.use(VueSocialAuth, authConfig.socialAuth);


    export default {
        computed: {
            ...mapGetters(['getUser']),
        },
        components: {
            SiteLogoSVG,
            ErrorMessage,
            LanguageSwitcher,
        },
        data() {
            return {
                form: {
                    email: "",
                    password: ""
                },
                errors: {
                    email: "",
                    password: ""
                },
                isLoading: false,
                timeZone: "UTC"
            }
        },
        methods: {
            ...mapActions([USER_LOGGED_IN, SWITCH_TO_ACCOUNT]),
            signIn() {
                if (this.validateForm()) {
                    // this.isLoading = true;
                    client().post('/sign-in', this.form)
                        .then(({data: {data}}) => {
                            this.isLoading = false;
                            this[USER_LOGGED_IN](data);

                            if (data.type === USER_TYPE.CANDIDATE) {
                                // go to candidate dashboard
                                return this.$router.push({name: 'dashboard'});
                            } else {
                                // go to subscribe page if not subscribed to any package
                                if (!data.package_id) {
                                    return this.$router.push({name: 'subscribe', query: this.$route.query});
                                }

                                // go to company create page if no company
                                if (!data.companies.length) {
                                    return this.$router.push({name: 'company.create'});
                                }
                                // go to current company if set
                                if (data.current_company) {
                                    let company = _.find(data.companies, {id: data.current_company});
                                    this[SWITCH_TO_ACCOUNT](company);
                                    return this.$router.push({name: 'dashboard'});
                                }
                                // go to first company
                                if (data.companies.length) {
                                    let company = _.first(data.companies);
                                    this[SWITCH_TO_ACCOUNT](company);
                                    return this.$router.push({name: 'dashboard'});
                                }

                                return this.$router.push({name: 'dashboard'});
                            }
                        })
                        .catch(error => {
                            let errors = error.response.data.message;
                            if (typeof errors === 'object') {
                                this.errors = _helper.serializeValidationMessage(errors);
                            } else {
                                this.$toast.error(errors);
                            }
                            this.isLoading = false;
                        });
                }
            },

            AuthProvider(provider) {
                this.$auth.authenticate(provider).then(response => {
                    this.SocialLogin(provider, response)
                }).catch(err => {
                    this.$toast.error('Cannot login with social.');
                })
            },

            SocialLogin(provider, response) {
                client().post('/sign-in-oauth/' + provider, response).then(({data: {data}}) => {
                    this[USER_LOGGED_IN](data);
                    // this.$router.push({name: 'dashboard', query: this.$route.query});

                    // go to subscribe page if not subscribed to any package
                    if (!data.package_id) {
                        return this.$router.push({name: 'subscribe', query: this.$route.query});
                    }

                    // go to company create page if no company
                    if (!data.companies.length) {
                        return this.$router.push({name: 'company.create'});
                    }
                    // go to current company if set
                    if (data.current_company) {
                        let company = _.find(data.companies, {id: data.current_company});
                        this[SWITCH_TO_ACCOUNT](company);
                        return this.$router.push({name: 'dashboard'});
                    }
                    // go to first company
                    if (data.companies.length) {
                        let company = _.first(data.companies);
                        this[SWITCH_TO_ACCOUNT](company);
                        return this.$router.push({name: 'dashboard'});
                    }

                    return this.$router.push({name: 'dashboard'});
                }).catch(err => {
                    this.$toast.error('Cannot login with social.');

                });
            },

            validateForm() {
                this.errors.email = "";
                this.errors.password = "";

                if (this.form.email.length < 1 || !_helper.validateEmail(this.form.email)) {
                    this.errors.email = "Please type a valid email address";
                    return false;
                }

                if (this.form.password.length < 8) {
                    this.errors.password = "Password should be at least 8 characters long";
                    return false;
                }

                return true;
            }
        },
        mounted() {
            this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            _helper.setCookie('time_zone', this.timeZone, 1);
        }
    }
</script>


<style>
    /*    For UI & Feedback CSS */
    @media all and (max-width: 460px) {
        .betterdocs-widget-container {
            right: auto !important;
            bottom: 0;
            right: 100%;
        }
        .betterdocs-launcher[type="button"].betterdocs-launcher {
            left: 0px;
            bottom: 0px;
            height: 40px;
            width: 40px;
            margin: 5px;
        }

        #userback_button_container .userback-button {
            right: calc(100% - 45px) !important;
            bottom: 90px !important;
            top: auto !important;
        }

        .sidebar__nav__item__logout {
            display: none;
        }

        #userback_button_container .userback-button {
            z-index: 9998 !important;
        }
    }
</style>
